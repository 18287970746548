/*
EST = America/New_York
CST = America/Chicago
MST = America/Denver 
PST = America/Los_Angeles
AST = America/Anchorage
HST = Pacific/Honolulu
*/

interface State {
    abbreviation: string;
    value: string;
    timezone: string;
}

interface CAStateType {
    AB: State;
    BC: State;
    MB: State;
    NB: State;
    NL: State;
    NT: State;
    NS: State;
    NU: State;
    ON: State;
    PE: State;
    QC: State;
    SK: State;
    YT: State;
}

interface USStatesType {
    NA: State;
    AL: State;
    AK: State;
    AZ: State;
    AR: State;
    CA: State;
    CO: State;
    CT: State;
    DC: State;
    DE: State;
    FL: State;
    GA: State;
    HI: State;
    ID: State;
    IL: State;
    IN: State;
    IA: State;
    KS: State;
    KY: State;
    LA: State;
    ME: State;
    MD: State;
    MA: State;
    MI: State;
    MN: State;
    MS: State;
    MO: State;
    MT: State;
    NE: State;
    NV: State;
    NH: State;
    NJ: State;
    NM: State;
    NY: State;
    NC: State;
    ND: State;
    OH: State;
    OK: State;
    OR: State;
    PA: State;
    RI: State;
    SC: State;
    SD: State;
    TN: State;
    TX: State;
    UT: State;
    VT: State;
    VA: State;
    WA: State;
    WV: State;
    WI: State;
    WY: State;
}

export const USStates: USStatesType = Object.freeze({
    NA: Object.freeze({ abbreviation: '', value: '', timezone: '' }),
    AL: Object.freeze({ abbreviation: 'AL', value: 'Alabama', timezone: 'America/Chicago' }),
    AK: Object.freeze({ abbreviation: 'AK', value: 'Alaska', timezone: 'America/Anchorage' }),
    AZ: Object.freeze({ abbreviation: 'AZ', value: 'Arizona', timezone: 'America/Denver' }),
    AR: Object.freeze({ abbreviation: 'AR', value: 'Arkansas', timezone: 'America/Chicago' }),
    CA: Object.freeze({ abbreviation: 'CA', value: 'California', timezone: 'America/Los_Angeles' }),
    CO: Object.freeze({ abbreviation: 'CO', value: 'Colorado', timezone: 'America/Denver' }),
    CT: Object.freeze({ abbreviation: 'CT', value: 'Connecticut', timezone: 'America/New_York' }),
    DC: Object.freeze({ abbreviation: 'DC', value: 'Washington D.C.', timezone: 'America/New_York' }),
    DE: Object.freeze({ abbreviation: 'DE', value: 'Delaware', timezone: 'America/New_York' }),
    FL: Object.freeze({ abbreviation: 'FL', value: 'Florida', timezone: 'America/New_York' }),
    GA: Object.freeze({ abbreviation: 'GA', value: 'Georgia', timezone: 'America/New_York' }),
    HI: Object.freeze({ abbreviation: 'HI', value: 'Hawaii', timezone: 'Pacific/Honolulu' }),
    ID: Object.freeze({ abbreviation: 'ID', value: 'Idaho', timezone: 'America/Denver' }),
    IL: Object.freeze({ abbreviation: 'IL', value: 'Illinois', timezone: 'America/Chicago' }),
    IN: Object.freeze({ abbreviation: 'IN', value: 'Indiana', timezone: 'America/New_York' }),
    IA: Object.freeze({ abbreviation: 'IA', value: 'Iowa', timezone: 'America/Chicago' }),
    KS: Object.freeze({ abbreviation: 'KS', value: 'Kansas', timezone: 'America/Chicago' }),
    KY: Object.freeze({ abbreviation: 'KY', value: 'Kentucky', timezone: 'America/Chicago' }),
    LA: Object.freeze({ abbreviation: 'LA', value: 'Louisiana', timezone: 'America/Chicago' }),
    ME: Object.freeze({ abbreviation: 'ME', value: 'Maine', timezone: 'America/New_York' }),
    MD: Object.freeze({ abbreviation: 'MD', value: 'Maryland', timezone: 'America/New_York' }),
    MA: Object.freeze({ abbreviation: 'MA', value: 'Massachusetts', timezone: 'America/New_York' }),
    MI: Object.freeze({ abbreviation: 'MI', value: 'Michigan', timezone: 'America/New_York' }),
    MN: Object.freeze({ abbreviation: 'MN', value: 'Minnesota', timezone: 'America/Chicago' }),
    MS: Object.freeze({ abbreviation: 'MS', value: 'Mississippi', timezone: 'America/Chicago' }),
    MO: Object.freeze({ abbreviation: 'MO', value: 'Missouri', timezone: 'America/Chicago' }),
    MT: Object.freeze({ abbreviation: 'MT', value: 'Montana', timezone: 'America/Denver' }),
    NE: Object.freeze({ abbreviation: 'NE', value: 'Nebraska', timezone: 'America/Chicago' }),
    NV: Object.freeze({ abbreviation: 'NV', value: 'Nevada', timezone: 'America/Los_Angeles' }),
    NH: Object.freeze({ abbreviation: 'NH', value: 'New Hampshire', timezone: 'America/New_York' }),
    NJ: Object.freeze({ abbreviation: 'NJ', value: 'New Jersey', timezone: 'America/New_York' }),
    NM: Object.freeze({ abbreviation: 'NM', value: 'New Mexico', timezone: 'America/Denver' }),
    NY: Object.freeze({ abbreviation: 'NY', value: 'New York', timezone: 'America/New_York' }),
    NC: Object.freeze({ abbreviation: 'NC', value: 'North Carolina', timezone: 'America/New_York' }),
    ND: Object.freeze({ abbreviation: 'ND', value: 'North Dakota', timezone: 'America/Chicago' }),
    OH: Object.freeze({ abbreviation: 'OH', value: 'Ohio', timezone: 'America/New_York' }),
    OK: Object.freeze({ abbreviation: 'OK', value: 'Oklahoma', timezone: 'America/Chicago' }),
    OR: Object.freeze({ abbreviation: 'OR', value: 'Oregon', timezone: 'America/Los_Angeles' }),
    PA: Object.freeze({ abbreviation: 'PA', value: 'Pennsylvania', timezone: 'America/New_York' }),
    RI: Object.freeze({ abbreviation: 'RI', value: 'Rhode Island', timezone: 'America/New_York' }),
    SC: Object.freeze({ abbreviation: 'SC', value: 'South Carolina', timezone: 'America/New_York' }),
    SD: Object.freeze({ abbreviation: 'SD', value: 'South Dakota', timezone: 'America/Chicago' }),
    TN: Object.freeze({ abbreviation: 'TN', value: 'Tennessee', timezone: 'America/Chicago' }),
    TX: Object.freeze({ abbreviation: 'TX', value: 'Texas', timezone: 'America/Chicago' }),
    UT: Object.freeze({ abbreviation: 'UT', value: 'Utah', timezone: 'America/Denver' }),
    VT: Object.freeze({ abbreviation: 'VT', value: 'Vermont', timezone: 'America/New_York' }),
    VA: Object.freeze({ abbreviation: 'VA', value: 'Virginia', timezone: 'America/New_York' }),
    WA: Object.freeze({ abbreviation: 'WA', value: 'Washington', timezone: 'America/Los_Angeles' }),
    WV: Object.freeze({ abbreviation: 'WV', value: 'West Virginia', timezone: 'America/New_York' }),
    WI: Object.freeze({ abbreviation: 'WI', value: 'Wisconsin', timezone: 'America/Chicago' }),
    WY: Object.freeze({ abbreviation: 'WY', value: 'Wyoming', timezone: 'America/Denver' }),
});


export const CAStates: CAStateType = Object.freeze({
    NA: Object.freeze({ abbreviation: '', value: '', timezone: '' }),
    AB: Object.freeze({abbreviation: 'AB', value: 'Alberta', timezone: ''}),
    BC: Object.freeze({abbreviation: 'BC', value: 'British Columbia', timezone: ''}),
    MB: Object.freeze({abbreviation: 'MB', value: 'Manitoba', timezone: ''}),
    NB: Object.freeze({abbreviation: 'NB', value: 'New Brunswick', timezone: ''}),
    NL: Object.freeze({abbreviation: 'NL', value: 'Newfoundland and Labrador', timezone: ''}),
    NT: Object.freeze({abbreviation: 'NT', value: 'Northwest Territories', timezone: ''}),
    NS: Object.freeze({abbreviation: 'NS', value: 'Nova Scotia', timezone: ''}),
    NU: Object.freeze({abbreviation: 'NU', value: 'Nunavut', timezone: ''}),
    ON: Object.freeze({abbreviation: 'ON', value: 'Ontario', timezone: ''}),
    PE: Object.freeze({abbreviation: 'PE', value: 'Prince Edward Island', timezone: ''}),
    QC: Object.freeze({abbreviation: 'QC', value: 'Quebec', timezone: ''}),
    SK: Object.freeze({abbreviation: 'SK', value: 'Saskatchewan', timezone: ''}),
    YT: Object.freeze({abbreviation: 'YT', value: 'Yukon', timezone: ''})
});


export const CyberSourceCC = Object.freeze({
    visa: '001',
    mastercard: '002',
    amex: '003',
    discover: '004',
});

interface CountryType {
    states: USStatesType | CAStateType;
    abbreviation: string;
    value: string;
}

interface CountryStateType {
    US: CountryType;
    CA: CountryType;
}

export const CountriesWithStates: CountryStateType =  Object.freeze({
    US : Object.freeze({ states : USStates, abbreviation: 'US', value: 'United States'}),
    CA :  Object.freeze({ states : CAStates, abbreviation: 'CA', value: 'Canada'})
});

export const countryDropdownOptions = Object.entries(CountriesWithStates).map((countryArr) => {
    const countryObj = countryArr[1];
    return {
        label: countryObj.abbreviation,
        value: countryObj.value,
    };
});

export const getCountryStates = (country: string) => {
     return Object.entries(CountriesWithStates).filter((dat) => dat[1].abbreviation === country).map((arr)=> stateDropdownOptionsX(arr[1].states))[0]
};

export const stateDropdownOptionsX = (states: USStatesType | CAStateType) => Object.entries(states).map((stateArr) => {
    const stateObj = stateArr[1];
    return {
        label: stateObj.abbreviation,
        value: stateObj.abbreviation,
    };
});

export const stateDropdownOptions = Object.entries(USStates).map((stateArr) => {
    const stateObj = stateArr[1];
    return {
        label: stateObj.abbreviation,
        value: stateObj.abbreviation,
    };
});
